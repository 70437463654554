import request from "@/utils/http";

export function setOrderImei(params = {}) {
    return request({
        url: '/Group/setOrderImei',
        method: 'post',
        data: params
    })
}
export function replaceType() {
    return request({
        url: '/Group/replaceType',
        method: 'get'
    })
}
export function getOrderImeiInfo(params = {}) {
    return request({
        url: '/Group/getOrderImeiInfo',
        method: 'post',
        data: params
    })
}