<template>
  <div class="page-container">
    <section-title>注意事项</section-title>
    <notice :content="notice | decodeHTML"></notice>

    <section-title show-asterisk>换机证明</section-title>
    <notice class="mb30">请按照下方示例图片上传新机弹出IMEI的正面照 (拨号处输入*#06#)
      +身份证正面照+换机证明的图片。</notice>
    <pic-uploader @change="(url) => {
        form.proofPic = url;
      }
      " :src="form.proofPic" :example="require('@/assets/images/examplePic/example11.png')"></pic-uploader>

    <section-title show-asterisk>新机正面照</section-title>
    <notice class="mb30">请在手机屏幕调出新机IMEI号或序列号</notice>
    <pic-uploader @change="(url) => {
        form.phoneFrontPic = url;
      }
      " :src="form.phoneFrontPic" :example="require('@/assets/images/examplePic/example12.png')"></pic-uploader>

    <section-title show-asterisk>变更信息</section-title>

    <cell-input title="姓名" placeholder="请输入姓名" v-model="form.name"></cell-input>
    <!--  TODO id_card  -->
    <cell-input title="身份证" placeholder="请输入身份证号" v-model="form.idCard"></cell-input>

    <cell-input title="手机号码" placeholder="请输入手机号码" type="tel" v-model="form.phone"></cell-input>

    <cell-input title="旧IMEI号" placeholder="请输入旧手机IMEI码" v-model="form.oldIMEI"></cell-input>

    <scan-code title="新IMEI号" placeholder="请输入或扫描新机IMEI码" v-model="form.newIMEI" @myInput="imitInput"></scan-code>

    <field-piker label="更换原因" title="请选择更换原因" :columns="reasonList" placeholder="请选择IMEI更换原因"
      v-model="form.reason"></field-piker>

    <field-piker label="险种类别" title="请选择险种类别" :columns="insuranceList" placeholder="请选择险种类别" v-model="form.type"
      value-key="instype_name"></field-piker>

    <my-button class="submit-btn" :type="isDone ? 'primary' : 'default'" :disabled="!isDone"
      @click="submit">提交申请</my-button>
  </div>
</template>

<script>
import { replaceType, setOrderImei } from "@/api/replaceIMEI";
import { article, insuranceType } from "@/api";
import { isFilled } from "@/utils";

export default {
  name: "Apply",
  props: {
    phoneType: String,
  },
  data() {
    return {
      notice: "",
      form: {
        name: "",
        phone: "",
        oldIMEI: "",
        newIMEI: "",
        reason: "",
        type: "",
        proofPic: "",
        phoneFrontPic: "",
        idCard: "",
      },
      insuranceList: [],
      reasonList: [],
      isDone: false,
    };
  },
  mounted() {
    console.log(11);
    article(2).then((res) => {
      this.notice = res.data && res.data.content;
    });
    let orderData = JSON.parse(sessionStorage.getItem("data") || "{}");
    this.form.name = orderData.name || "";
    this.form.phone = orderData.phone || "";
    this.form.oldIMEI = orderData.old_imei
      ? orderData.old_imei
      : orderData.imei || "";
    this.form.newIMEI = orderData.old_imei ? orderData.imei : "" || "";
    this.form.idCard = orderData.id_card || "";
    this.form.phoneFrontPic = orderData.old_imei ? orderData.tem_img : "" || "";
    this.form.proofPic = orderData.tem_planes || "";
    this.form.reason = orderData.replace_reason || "";
    this.form.type = {
      instype_name: orderData.instype_name || "",
      instype_id: orderData.instype_id || "",
    };

    insuranceType({ type: this.phoneType }).then((res) => {
      this.insuranceList = res.data;
    });
    replaceType().then((res) => {
      let list = res.data || [];
      this.reasonList = list.map((item) => item.replace_name);
    });
  },
  watch: {
    form: {
      handler(form) {
        this.checkForm(form);
      },
      deep: true,
    },
  },
  methods: {
    // 解决串号组件苹果手机无法识别@change问题
    imitInput(e) {
      this.form.newIMEI = e.target.value;
    },
    checkForm(form) {
      let isDone = true;
      Object.keys(form).forEach((key) => {
        if (!isFilled(form[key])) {
          isDone = false;
        }
      });
      this.isDone = isDone;
    },
    submit() {
      let { userInfo } = this.$store.state;
      console.log(`%c userInfo`, "color:red;font-size:20px", userInfo);
      let { form } = this;
      // if (!new RegExp(/^([\u4E00-\uFA29]|[\uE7C7-\uE7F3])*$/).test(this.form.name.trim())) {
      //   this.$toast('请输入正确中文名');
      //   return;
      // }
      if (!new RegExp(/^[1][3456789][0-9]{9}$/).test(this.form.phone)) {
        this.$toast("请输入正确手机号码");
        return;
      }

      setOrderImei({
        openid: userInfo.openid,
        nickname: userInfo.nickname,
        headimg: userInfo.headimg,
        tem_img: form.phoneFrontPic,
        tem_planes: form.proofPic,
        instype_id: form.type.instype_id,
        name: form.name,
        phone: form.phone,
        id_card: form.idCard,
        imei: form.newIMEI,
        old_imei: form.oldIMEI,
        replace_reason: form.reason,
      })
        .then((res) => {
          if (res && res.code) {
            this.$myDialog({
              type: "success",
              title: "提交成功",
              confirmButtonText: "查看详情",
              beforeClose: (action, done) => {
                if (action === "confirm") {
                  done();

                  this.$router.push(
                    "/replaceIMEI/detail/" + res.data.order_imei_id
                  );
                } else {
                  done();
                }
              },
            });
          } else {
            this.$toast(res.msg || "审核申请提交失败");
          }
        })
        .catch((err) => {
          this.$toast((err && err.msg) || "审核申请提交失败");
        });
    },
  },
};
</script>

<style scoped>
.replace-IMEI-apply {
  padding: 0 30px;
}

.mb30 {
  margin-bottom: 30px;
}

.submit-btn {
  width: calc(100vw - 150px);
  margin: 60px auto 0;
}
</style>
